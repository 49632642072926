<template>
	<div v-if="hasErrors" class="mt-3">
		<div v-for="(error, key) in errors" :key="key" class="text-red-600">
			<SvgAsset class="mr-2" :name="'error'"></SvgAsset><span class="ml-2 text-error">{{ error }}</span>
		</div>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { usePage } from '@inertiajs/inertia-vue3';
import SvgAsset from './SvgAsset.vue';

const errors = computed(() => usePage().props.value.errors);
const hasErrors = computed(() => Object.keys(errors.value).length > 0);
</script>
<style lang="scss">
.text-error {
	font-family: "Open Sans", sans-serif;
	font-size: 14px;
	font-weight: 400;
	line-height: 18px;
	text-align: left;
	color: rgba(215, 74, 65, 1);
}
</style>